.img {
    max-width: 400px;
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    object-fit: cover;
}

@media (max-width: 500px) {
    .img {
        max-width: 300px;
    }
}

@media (max-width: 360px) {
    .img {
        max-width: 200px;
    }
}